<template lang="pug">
  AppNestedRoutesTabs(
    :routes="routes"
    :default-prevented="isDirty"
  )
</template>

<script>
  // mixins
  import withStoreModule from "@/mixins/withStoreModule"

  // stores
  import inventoryGroupsModule from "@/config/store/company_system/shops_settings/inventory_groups"
  import shopsModule from "@/config/store/company_system/shops_settings/shops/basic_settings"
  import accessSettingModule from "@/config/store/company_system/shops_settings/shops/access_settings"
  import timeSettingModule from "@/config/store/company_system/shops_settings/shops/time_settings"

  const inventoryGroupsMixin = withStoreModule(inventoryGroupsModule, {
    name: "companiesInventoryGroups",
    readers: { isInventoryGroupDirty: "isDirty" }
  })

  const shopsMixin = withStoreModule(shopsModule, {
    name: "companiesShops",
    readers: { isShopDirty: "isDirty" }
  })

  const accessSettingMixin = withStoreModule(accessSettingModule, {
    name: "companiesShopAccessSetting",
    readers: { isAccessSettingDirty: "isDirty" }
  })

  const timeSettingMixin = withStoreModule(timeSettingModule, {
    name: "companiesShopTimeSettingMixin",
    readers: { isTimeSettingDirty: "isDirty" }
  })

  export default {
    components: {
      AppNestedRoutesTabs: () => import("@/components/AppNestedRoutesTabs")
    },

    mixins: [inventoryGroupsMixin, shopsMixin, accessSettingMixin, timeSettingMixin],

    computed: {
      isDirty({ isInventoryGroupDirty, isShopDirty, isAccessSettingDirty, isTimeSettingDirty }) {
        return isInventoryGroupDirty || isShopDirty || isAccessSettingDirty || isTimeSettingDirty
      },

      routes() {
        return [
          {
            name: "Shops",
            title: this.$t("company_system.shop_settings.shops.title")
          },
          {
            name: "InventoryGroups",
            title: this.$t("company_system.shop_settings.inventory_groups.title")
          }
        ]
      }
    }
  }
</script>

<style lang="sass" scoped></style>
